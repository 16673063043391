@import url('https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i');

$blue: #4e73df;
$green:#18cd57;
$red: #f30131;

.colorBlue {
  color:$blue;
}

.colorRed {
  color:$red;
  font-weight:bold;
}

.colorGreen {
  color:$green;
  font-weight:bold;
}

.colorYellow {
  color:#eed202;
  font-weight:bold;
}
.rounded {
  border-radius: 64px;
}
.rounded-left {
  border-left-radius: 64px;
}
.rounded-right {
  border-right-radius: 64px;
}
.topbar {
  height:6rem;
}
.sidebar-dark{
  .sidebar-brand {
    height:6rem;
  }
} 
.pagination {
  align-items: center;
  justify-content: center;
  margin-bottom:80px;
  .btn-primary {
    border-color: transparent;
    background-color:transparent;
    color:$blue;
    font-size:1.2rem;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    border:solid 1px $blue;
    padding:10px 40px 10px 40px;
  }
  .btn-primary:hover {
      background-color: $blue;
      color:white !important; 
  }
}
.table-responsive {
  overflow-x: hidden; 
}
.filter-table-container {
  text-align:center;
  @media (max-width: 768px) {
    text-align:left;
  }
}
#filter-table {
  justify-content: center;
  align-content: center;
  margin-top:20px;
  
  .btn-group {
    margin-right:12px;
    @media (max-width: 768px) { 
      
    }
  }
  .btn-primary {
    border-color: transparent;
    background-color:transparent;
    color:$blue;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    border:solid 1px $blue;
    font-size:1.2rem;
    @media (max-width: 768px) { 
      font-size:1.1rem;
      margin-top:20px;
      
    }
  }
  .dropdown{
    @media (max-width: 768px) { 
      width:100% !important;
    }
    .dropdown-toggle {
      @media (max-width: 768px) { 
        width:50% !important;
      }
      @media (max-width: 575.98px) { 
        width:100% !important;
      }
    } 
  }
  
  .btn-primary:hover {
      background-color: $blue;
      color:white !important; 
  }
  .active.btn-primary, .active .btn-primary {
    background-color: $blue;
    color:white !important;
  }
  .btn-group .dropdown-toggle {
    
    min-width:140px;
    text-align:left;
  }
  .btn-group button {
    
    min-width:50px;
  }
  .btn-group .btn-refresh {
    margin-left:1em;
    min-width:100px;
  }
}
.custom-buy-button {
  margin-right:0 !important;
  @media (max-width: 768px) { 
    margin-top:-20px !important;
  }
}
.custom-sell-button {
  margin-left:0;
  margin-right:20px !important;
  @media (max-width: 768px) { 
    margin-top:-20px !important;
  }
}

table {
  tr {
     th {
			font-weight:bold;
		}
  }
}

.card-rating-label {
  position:absolute;
  right:10px;
  top:15px;
  color:white;
  font-weight:bold;
  padding:4px 10px 2px 10px;
  font-size:0.65rem;
}

.custom-stock-summary {
  .card-body {
    min-height:400px;
  }
  .card-img-top-container {
    display:flex;
    width:100%;
    img {
      width:auto;
      height:100px;
      align-content: center!important;
      justify-content: center!important;
      text-align: center;
      margin:10px auto 20px;
    }
  }
  .card-img-no-image-space-filler {
    height:100px;
    margin:10px auto 20px;
  }
  
  .card-rating {
    .colorGreen {
      background-color:$green;
      @extend .card-rating-label
    }
    .colorRed {
      background-color:$red;
      @extend .card-rating-label
    }
    .colorYellow {
      background-color:#eed202;
      @extend .card-rating-label
    }
  }

  .ticker {
    font-weight:bold;
    font-size:1.2rem;
  } 
  .rating {
    color:$green;
    font-weight:bold;
    font-size:1.2rem;
    margin-bottom:0px;
  }
  .company-name {
    color:#333;
  }
  .card-text {
    font-size:0.8rem;
    .section {
      border-top:dotted 1px;
      display: flex;
      padding-top:5px;
      label {
       
        width:50%;
      }
      .value {
        text-align:right;
        width:50%;
      }
    }
  }
}
nav {
  .search-filter {
    text-align:right;
  }
}
.container {
  max-width:none;
}
.stock-summary {
  margin-top:30px;
}

.input-group {
  align-content: center;
  justify-content: center;
  #search-table {
    min-width:300px;
    @media (max-width: 575.98px) { 
      min-width:150px;
    }
    input[type=text] {
      font-size:1.2rem;
      border-radius: 64px !important;
      padding-left:10%;
      @media (max-width: 575.98px) { 
        font-size:0.8rem;
      }
    }
    
  }
  .input-group-append {
    button {
      font-size:1.2rem;
      border-radius: 64px;
      margin-left:-41px;
      @media (max-width: 575.98px) { 
        font-size:0.8rem;
      }
    }
  }
}

/*
#myChart-wrapper {
  margin: auto;
}

.zc-ref {
  display: none;
}

#myChart-license-text {
  display: none !important;
}

#myChart {
  max-width: 1100px;
  width: 100%;
  overflow: visible;
}*/

.zc-rel {
  height:268px;
}

.text-buy {
  @extend .colorGreen
}
.text-caution {
  @extend .colorYellow
}
.text-sell {
  @extend .colorRed
}
.custom-market-overview {
  /*padding:15% 5% 15% 5%;*/
  /*#myMarketChart {
    margin:0;
    padding:0;  
  }*/
}
.custom-sector-overview {
  /*padding:15% 5% 15% 5%;*/
  #mySectorChart {
    /*width:100%;
    font-size:0.5rem;
    height:auto;
    margin:0;
    padding:0;  */
  }
}


.modal-title.h4 {
  color:#333;
}
/*carousels */
.swiper-container {
  margin-bottom:40px;
  .swiper-slide {
    
  }
}
.sidebar .sidebar-brand .sidebar-brand-text {
  @media (max-width: 768px) { 
    display:flex !important;
    img {
      width:100%;
      height:auto;
      min-width:80px;
    }
  }
}

#zingchart-react-2-license-text {
  display: none !important;
}
div[id*="license-text"] {
  display: none !important;
}

